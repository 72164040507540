body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.6;
}

/* Hero Section */
#hero {
    background: url('./assets/2bedroom.jpeg') no-repeat center center/cover;
    color: #fff;
    padding: 80px 20px; /* Add padding for smaller screens */
    text-align: center; /* Center-align text for better appearance on mobile */
}

#highlights .card {
    border: none;
    background: #f8f9fa;
    margin-bottom: 20px; /* Add spacing between cards on smaller screens */
}

#highlights .card-body {
    padding: 20px;
}

/* Section Padding */
#lead-magnet, #virtual-tour, #gallery, #contact {
    padding: 60px 20px; /* Add padding adjustments for mobile */
}

/* Gallery Section */
#gallery img {
    width: 100%;
    margin-bottom: 15px;
}

/* Social Links */
.social-links a {
    color: #fff;
    margin: 0 10px;
    font-size: 24px;
}

.social-links a:hover {
    text-decoration: none;
    color: #ddd;
}

/* Blog Page */
.blog-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.blog-list {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr; /* Single column layout for mobile */
}

.blog-post {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    transition: box-shadow 0.3s;
}

.blog-post img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.blog-post h2 {
    font-size: 1.5em;
    margin-top: 0.5em;
}

.blog-post p {
    color: #555;
}

.read-more {
    color: #007bff;
    text-decoration: none;
}

.read-more:hover {
    text-decoration: underline;
}

/* Blog Detail */
.blog-detail img {
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
}

/* Blog Detail Page Styling */
.blog-detail-page {
    background: no-repeat center center fixed;
    background-size: cover;
    min-height: 100vh;
    padding: 20px;
}

.blog-detail {
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent white background for readability */
    border-radius: 10px;
    padding: 20px;
    max-width: 800px;
    margin: auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
    #hero {
        padding: 60px 10px; /* Reduce padding on smaller screens */
        font-size: 1.2em; /* Adjust font size */
    }

    #highlights .card-body {
        padding: 15px; /* Reduce padding inside cards */
    }

    #lead-magnet, #virtual-tour, #gallery, #contact {
        padding: 40px 10px; /* Adjust section padding for mobile */
    }

    .blog-detail {
        padding: 15px; /* Reduce padding for mobile */
    }

    .blog-post h2 {
        font-size: 1.2em; /* Reduce heading size */
    }

    .blog-post p {
        font-size: 0.9em; /* Adjust text size for mobile */
    }
}

@media (max-width: 480px) {
    #hero {
        padding: 40px 10px; /* Further reduce padding on very small screens */
    }

    .blog-post h2 {
        font-size: 1em;
    }

    .blog-detail {
        padding: 10px; /* Minimize padding for very small devices */
    }

    .read-more {
        font-size: 0.9em;
    }
}
